









































































import { namespace } from 'vuex-class';
import './scss/PopupEditDocument.scss';
import { Component, Vue } from 'vue-property-decorator';

import { IProject } from '@store/modules/project/Interfaces';
import { AboutProjectActions } from '@store/modules/about-project/Types';
import { ProjectDocumentsActions } from '@store/modules/project-documents/Types';

const NSProject = namespace('storeProject');
const NSAboutProject = namespace('storeAboutProject');
const NSDocuments = namespace('storeProjectDocuments');

@Component({
    name: 'PopupEditDocument',
    components: {
        BasePopup: () => import('@components/popups/BasePopup.vue'),
        BaseButton: () => import('@components/buttons/BaseButton.vue'),
        BaseInput: () => import('@components/BaseInputs/BaseInput.vue'),
        BaseFileInput: () => import('@components/BaseFileInput/BaseFileInput.vue'),
        PopupConfirmation: () => import('@components/popups/PopupConfirmation.vue'),
    }
})

export default class PopupEditDocument extends Vue {
    $refs!: {
        basePopup: HTMLFormElement,
    }

    @NSProject.Getter('projectData') projectData!: IProject;
    @NSDocuments.Action(ProjectDocumentsActions.A_UPLOAD_FILE) uploadFiles!: (payload) => Promise<void>;
    @NSDocuments.Action(ProjectDocumentsActions.A_REMOVE_FILES) removeFiles!: (payload) => Promise<void>;
    @NSDocuments.Action(ProjectDocumentsActions.A_DOCUMENT_RENAME) docRename!: (payload) => Promise<void>;
    @NSAboutProject.Action(AboutProjectActions.A_EDIT_JURISTIC_PERSON) editJuristicPerson!: (payload) => Promise<void>;

    btnDisabled = false;

    fields = {
        title: '',
        files: [],
        filesNew: [],
        filesForRemove: [] as any[],
    };

    errors = {
        title: false,
    }

    selectedDocument: any = null;
    realtimeValidation: boolean = false;
    confirmRemoveFile: any[] = [];
    confirmRemovePopupShow: boolean = false;

    get projectId() {
        return this.projectData ? this.projectData.id.toString() : '';
    }

    get errorExists() {
        return Object.values(this.errors).some((item) => item);
    }

    getFileTitleForBtn(file) {
        return file.title.replace(`.${file.format}`, '')+'.'+file.format;
    }

    openPopup() {
        if (this.$refs.basePopup) {
            this.$refs.basePopup.openPopup();
        }
    }

    closePopup() {
        if (this.$refs.basePopup) {
            this.$refs.basePopup.closePopup();
        }
    }

    selectFile(files) {
        this.fields.filesNew = files;
    }

    checkSend() {
        this.validateFields();
        this.btnDisabled = true;
        if (!this.errorExists) {
            this.sendForm();
        }
        this.btnDisabled = false;
    }

    sendForm() {
        this.docRename({
            appId: this.projectId,
            id: this.selectedDocument.uuid,
            name: this.fields.title,
        }).then(() => {
            if (this.fields.filesForRemove.length) {
                this.removeFiles({
                    data: {
                        appId: this.projectId,
                        id: this.fields.filesForRemove,
                    },
                    idDoc: this.selectedDocument.uuid,
                }).then(() => this.$emit('edited', 'remove'));
            }
            if (this.fields.filesNew.length) {
                this.uploadFiles({
                    appId: this.projectId,
                    documentId: this.selectedDocument.uuid,
                    file: this.fields.filesNew[0],
                }).then(() => this.$emit('edited', 'upload'));
            }
        }).finally(() => {
            this.closePopup();
            this.resetFields();
        })
    }

    resetFields() {
        this.fields = {
            title: '',
            files: [],
            filesNew: [],
            filesForRemove: [],
        }
    }

    setSelectedDocument(val) {
        this.selectedDocument = val;
        this.fields.title = val.title;
        this.fields.files = val.files;
    }

    validateFields() {
        this.realtimeValidation = true;
        this.errors.title = !this.fields.title;
    }

    checkValidation() {
        if (this.realtimeValidation) {
            this.validateFields();
        }
    }

    confirmForRemove(id) {
        this.confirmRemoveFile.push(id);
        this.confirmRemovePopupShow = true;
    }

    onConfirmDeleteFiles() {
        this.fields.filesForRemove = this.confirmRemoveFile;
        this.confirmRemovePopupShow = false;
    }

    onCloseConfirmDeleteFiles() {
        this.confirmRemoveFile = [];
        this.confirmRemovePopupShow = false;
    }
}
